<template>
  <div class="creek-video-panel">

    <!--
    <div class="creek-video-panel-button-container">
      <button 
        @click="unmute()" 
        class="creek-video-panel-button"
        v-if="muted == true"
      >
        Start
      </button>
    </div>
    -->

    <video 
      ref="videoPlayer" 
      class="video-js vjs-default-skin"
    >
      <source
         :src="streamURL"
         type="application/x-mpegURL" 
      />
    </video>

  </div>
</template>

<script>

import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import '@videojs/themes/dist/city/index.css'

import axios from 'axios'


export default {
  name: 'CreekVideoPanel',
  data() {
    return {
      player: null,
      options: {
        controls: true, 
        autoplay: 'any', 
        preload: "auto",
        fill: true,
        fluid: true,
      },
      muted: true,
      intervals: {
        checkRemoteDJ: null,
        checkMuted: null,
      },
      currentSourceName: null,
      defaultSourceName: 'studio-1',
    }
  },
  computed: {
    streamURL(){
      return `https://werw-video.creek.org/segments/hls/dj-1.m3u8`
    }
  },
  methods: {
    unmute(){
      this.player.play()
      if(this.player){
        this.player.muted(false)
      }
      this.muted = false
      X('wooooo')
    },
    checkMuted(){
      if(!this.player){
        X('player unlinked?')
        return false
      }
      if(this.player && this.player.muted){
        this.muted = this.player.muted() ? true : false
        // X(this.player.muted())
      }
    },
    getVideoPath(sourceName){
      return `https://werw-video.creek.org/segments/hls/dj-1.m3u8`
      // return `https://werw-video.creek.org/segments/hls/${sourceName}.m3u8`
    },
    async checkRemoteDJ(){

      let response = null

      const URL = `https://werw-remote-dj.creek.org/bobcat-server/api/status`
      // const URL = `https://${window.location.hostname}/bobcat-server/api/status`

      response = await axios.get(URL)

      if(
        !response.data 
        || !response.data.instances
        || !response.data.instances['instance-1'].sources
      ){
        return null
      }

      const sources = response.data.instances['instance-1'].sources

      // Default to studio 1.
      let currentSourceNameFromAPI = null

      for(let sourceName in sources){

        // X('checking: ' + sourceName)

        const source = sources[sourceName]

        // X(source.volume)
        // X(parseInt(source.volume) > 0)
        // X(source.volume > 0)
        // X(source.volume == 0)
        // X(source.volume == 1)

        if(currentSourceNameFromAPI){
          continue
        }

        if(parseInt(source.volume) > 0 && source.isActive){
          currentSourceNameFromAPI = sourceName
          // X('yeahsdasdasdasd')
          // X(sourceName)
        }


      }

      if(
        currentSourceNameFromAPI 
        && this.currentSourceName != currentSourceNameFromAPI
      ){

        this.currentSourceName = currentSourceNameFromAPI

        const path = this.getVideoPath(this.currentSourceName)
        
        X('getting: ' + path)

        this.player.src(path)
      
      }

      if(!currentSourceNameFromAPI && this.currentSourceName != this.defaultSourceName){

        this.currentSourceName = this.defaultSourceName

        const path = this.getVideoPath(this.currentSourceName)
        
        X('getting default: ' + path)

        this.player.src(path)
      }


    }
  },
  mounted() {

    // alert(1)

    this.player = videojs(
      this.$refs.videoPlayer, 
      this.options, 
      function onPlayerReady() {
        X('onPlayerReady', this);
      }
    )

    // TODO remove this and keep checking.
    return

    setTimeout(() => {
      X('initial check')
      this.checkRemoteDJ()
    }, 1000)

    this.intervals.checkRemoteDJ = setInterval(this.checkRemoteDJ, 2000)
    this.intervals.checkMuted = setInterval(this.checkMuted, 2000)

    // setTimeout(() => {
    //   this.player.src('/segments/hls/dj-2.m3u8')
    //   console.log('x')
    // }, 5000)
  },
  beforeDestroy() {
      if (this.player) {
          this.player.dispose()
      }
  }
}
</script>