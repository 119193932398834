<template>
  <div class="home-page flatten">
    
    <div class="logo">
      <img 
        class="" 
        src="https://atxsoul-media.s3.us-west-002.backblazeb2.com/images/logo-home-corner.png" 
      />
      <!-- 
        
        https://kcsufm.com/wp-content/uploads/2019/02/KCSU-vector.png
        https://atxsoul-media.s3.us-west-002.backblazeb2.com/images/logo-home-corner.png 

      -->
    </div>

    <div class="broadcasts">

      <CreekBroadcastCard 
        v-for="broadcast in broadcasts" 
        :broadcast="broadcast" 
      />

    </div>

  </div>
</template>

<style lang="sass">
  .broadcasts
    margin: 0 10px
</style>


<script>

import CreekStudio from '@creek-website-toolkit/CreekStudio.js'
import CreekBroadcastCard from '@creek-website-toolkit/components/cards/BroadcastCard'
import CreekVideoPanel from '@creek-website-toolkit/components/panels/VideoPanel'

export default {
  name: 'HomePage',
  components: {
    CreekBroadcastCard,
    CreekVideoPanel,
  },
  data(){ 
    return {
      broadcasts: {}
    }
  },
  computed: {
    studio() { 
      return this.$store.state.creek.studio
    }
  },
  async created(){
    this.broadcasts = await CreekStudio.get('broadcasts')
  }
}

</script>
